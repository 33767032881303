<template>
  <v-container class="my-4">
    <v-row>
      <v-col class="card-title">Component Wiki</v-col>
    </v-row>
    <!-- Wiki Menu-->
    <v-row>
      <v-col cols="3" xl="2">
        <v-card class="py-2 px-1 rounded-lg shadow-regular">
          <v-list nav dense>
            <v-list-item-group mandatory color="blueRegular">
              <v-list-item
                v-for="(item, index) in selectMenu"
                :key="index"
                :to="{
                  name: menuOptions[index],
                }"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item"> </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <!-- Router view -->
      <v-col cols="9" xl="10">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'WikiMenu',

  data: () => ({
    selectMenu: [
      'Form Inputs',
      'Input Rules',
      'Form Controls',
      'Rich Text Editor',
      'Alerts & Badges',
      'Buttons',
      'Dialogs',
      'Paginations',
      'Empty States',
      'Error View',
      'Loading Animation',
      'Cards',
    ],

    menuOptions: {
      0: 'FormInputWiki',
      1: 'InputRulesWiki',
      2: 'FormControlWiki',
      3: 'RichTextEditorWiki',
      4: 'AlertsBadgesWiki',
      5: 'ButtonWiki',
      6: 'DialogWiki',
      7: 'PaginationWiki',
      8: 'EmptyStatesWiki',
      9: 'ErrorViewWiki',
      10: 'LoadingAnimationWiki',
      11: 'CardsWiki',
    },
  }),
};
</script>

<style scoped lang="scss"></style>
